import { useEffect, useState, useCallback, useMemo } from "react";
import Select from "react-select";
import {
  ResponsiveContainer,
  LineChart,
  AreaChart,
  Line,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
} from "recharts";
import { format } from "date-fns";
import Slider from "rc-slider";

import client from "./feathers";

import LevelDiffs from "./components/LevelDiffs";

const sensorService = client.service("sensor");
const waterLevelService = client.service("water-level");

function App() {
  const [maxTimestamp, setMaxTimestamp] = useState(Date.now() / 1000);
  const [defaultStartTimestamp, setDefaultStartTimestamp] = useState(
    maxTimestamp - 60 * 60 * 24
  );
  const [sensorId, setSensorId] = useState(null);
  const [sensors, setSensors] = useState([]);
  const [sensorData, setSensorData] = useState([]);
  // by default show one day
  const [startTimestamp, setStartTimestamp] = useState(defaultStartTimestamp);
  const [endTimestamp, setEndTimestamp] = useState(maxTimestamp);
  const [preferedDates, setPreferedDates] = useState([
    startTimestamp,
    endTimestamp,
  ]);

  const autoupdate = useMemo(() => {
    if (preferedDates[1] === maxTimestamp) {
      return true;
    }

    return false;
  }, [preferedDates, maxTimestamp]);

  const addData = useCallback(
    (data) => {
      console.log("received new data...");
      const newTimestamp = Date.now() / 1000;

      setMaxTimestamp(Date.now() / 1000);

      if (autoupdate) {
        setPreferedDates((v) => [v[0], newTimestamp]);

        if (data.sensorId === sensorId) {
          setSensorData((d) => [
            ...d,
            {
              date: data.date,
              level: data.level,
            },
          ]);
        }
      }
    },
    [sensorId, autoupdate]
  );

  const loadSensors = async () => {
    try {
      const test = await sensorService.find({});
      setSensors(test.data);
      // console.log(test);
    } catch (e) {
      console.log(e);
    }
  };

  const loadSensorData = useCallback(async () => {
    try {
      const test = await waterLevelService.find({
        query: {
          sensorId: sensorId,
          date: {
            $gte: new Date(startTimestamp * 1000).toISOString(),
            $lte: new Date(endTimestamp * 1000).toISOString(),
          },
          $limit: 9999,
          $sort: {
            date: 1,
          },
        },
      });
      setSensorData(test.data);
    } catch (e) {
      console.log(e);
    }
  }, [sensorId, startTimestamp, endTimestamp]);

  useEffect(() => {
    // console.log("lel");
    loadSensors();
  }, []);

  useEffect(() => {
    // console.log("lol");
    loadSensorData();
    // Listen `created` with a handler reference
  }, [loadSensorData]);

  useEffect(() => {
    console.log("registering for updates...");
    waterLevelService.on("created", (data) => {
      addData(data);
    });

    return () => {
      console.log("unregistering from updates...");
      waterLevelService.removeListener("created");
    };
  }, [addData]);

  const options = sensors.map((sensor) => {
    return {
      value: sensor._id,
      label: sensor.name,
    };
  });

  const graphData = sensorData.map((data) => {
    return {
      // date: format(data.date, "dd.MM. HH:mm"),
      date: new Date(data.date).valueOf(),
      level: data.level,
    };
  });

  const minLevel =
    graphData.length > 0
      ? graphData.reduce((prev, curr) =>
          prev.level < curr.level ? prev : curr
        )
      : 0;
  const maxLevel =
    graphData.length > 0
      ? graphData.reduce((prev, curr) =>
          prev.level > curr.level ? prev : curr
        )
      : 0;

  const marks = {
    1703804400: "29.12.2023 00:00",
    [maxTimestamp]: "JETZT",
  };

  const handleSetDaterange = (values) => {
    setStartTimestamp(values[0]);
    setEndTimestamp(values[1]);
  };

  return (
    <div className="App">
      <div className="header-wrapper">
        <h1>Mobile Pegelstand Sensoren in Lilienthal</h1>
      </div>
      <div className="controls">
        <div className="text-wrapper">Messstation wählen:</div>
        <div className="select-wrapper">
          <Select
            options={options}
            onChange={(value) => {
              setSensorId(value.value);
            }}
            placeholder="Auswählen..."
          />
        </div>
      </div>
      <div className="controls">
        <div className="text-wrapper">Datumsbereich wählen:</div>
        <div className="slider-wrapper">
          <Slider
            range
            allowCross={false}
            defaultValue={[defaultStartTimestamp, maxTimestamp]}
            marks={marks}
            min={1703804400}
            max={maxTimestamp}
            step={3600}
            value={preferedDates.length > 0 ? preferedDates : undefined}
            onChange={(values) => {
              setPreferedDates(values);
            }}
            onChangeComplete={handleSetDaterange}
          />
        </div>
      </div>
      <div className="range-wrapper">
        {format(
          (preferedDates[0] || startTimestamp) * 1000,
          "dd.MM.yyyy HH:mm"
        )}{" "}
        Uhr -{" "}
        {format((preferedDates[1] || endTimestamp) * 1000, "dd.MM.yyyy HH:mm")}{" "}
        Uhr
        <br />
        <br />
        {sensorData.length > 1 && (
          <>
            Der letzte Wert vom{" "}
            {format(sensorData[sensorData.length - 1].date, "dd.MM.yyyy HH:mm")}{" "}
            Uhr beträgt {sensorData[sensorData.length - 1].level}cm
          </>
        )}
      </div>
      <div className="container" style={{ width: "100%", height: "600px" }}>
        <ResponsiveContainer width="100%" height={600}>
          <AreaChart
            data={graphData}
            margin={{ top: 5, right: 20, bottom: 100, left: 15 }}
          >
            {graphData.length > 0 && (
              <ReferenceLine
                y={graphData[graphData.length - 1].level}
                label={{
                  value: `Aktueller Pegel: ${
                    graphData[graphData.length - 1].level
                  }cm`,
                  // angle: -90,
                  // position: "left",
                  position: "insideBottom",
                }}
                stroke="black"
              />
            )}
            <Area
              type="monotone"
              dataKey="level"
              stroke="#002fc8"
              strokeWidth={4}
              fill="#0092c8"
            />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            {/* <XAxis dataKey="date" angle={-65} textAnchor="end" /> */}
            <XAxis
              type="number"
              dataKey="date"
              angle={-65}
              textAnchor="end"
              domain={[
                preferedDates[0] * 1000 || 0,
                preferedDates[1] * 1000 || 0,
              ]}
              tickFormatter={(timeStr) => format(timeStr, "dd.MM.yyyy HH:mm")}
              // interval={9000}
              tickCount={100}
            />
            <YAxis
              domain={[minLevel.level, maxLevel.level]}
              label={{ value: "cm", angle: -90, position: "left" }}
            />
            <Tooltip
              // formatter={(value, name, props) => name === 'level' : `formatted ${name} ${value}`}
              labelFormatter={(timeStr) => format(timeStr, "dd.MM.yyyy HH:mm")}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div>
        <LevelDiffs maxTimestamp={maxTimestamp} />
      </div>
      <div className="description">
        <p>
          <strong>
            Die Daten auf dieser Seite haben weder einen Anspruch auf
            Vollständigkeit noch auf Richtigkeit.
          </strong>
          <br />
          Die Werte werden alle 15 Minuten mit den Daten des THW abgeglichen und
          in einer eigenen Datenbank vorgehalten, um einen schnelleren Zugriff
          und eine längere Historie zu ermöglichen und die Server des THW zu
          schonen.
        </p>
        <p>
          Dieses Projekt steht weder in Verbindung mit der Gemeinde Lilienthal
          noch mit dem THW.
          <br />
          Es handelt sich um ein rein privates Hobby-Projekt von einem
          Lilienthaler für Lilienthaler.
        </p>
      </div>
    </div>
  );
}

export default App;
