import io from "socket.io-client";
import { feathers } from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";

const { REACT_APP_SOCKET } = process.env;

const socket = io(REACT_APP_SOCKET);
const client = feathers();

client.configure(socketio(socket));

export default client;
