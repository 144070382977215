import GaugeChart from "react-gauge-chart";
import { format } from "date-fns";

function convertRange(value, r1, r2) {
  return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
}

const SensorDiff = ({ data }) => {
  // console.log(data);

  return (
    <div className="gauge-wrapper">
      <div className="gauge-title">{data.name}</div>
      <div className="gauge">
        <GaugeChart
          textColor="#000"
          nrOfLevels={420}
          arcsLength={[0.5, 0.5]}
          colors={["#5BE12C", "#EA4228"]}
          percent={convertRange(data.diff, [-15, 15], [0, 1])}
          arcPadding={0.02}
          id="gauge-chart1"
          formatTextValue={() => `${data.diff}cm`}
        />
      </div>
      <div className="gauge-values">
        {format(data.levels[1].date, "dd.MM. HH:mm")} ({data.levels[1].level}cm)
        - {format(data.levels[0].date, "dd.MM. HH:mm")} (
        <strong>{data.levels[0].level}</strong>
        cm)
      </div>
    </div>
  );
};

export default SensorDiff;
