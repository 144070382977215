import { useCallback, useEffect, useState } from "react";

import client from "./../feathers";

import SensorDiff from "./SensorDiff";

const sensorService = client.service("sensor");
const waterLevelService = client.service("water-level");

const LevelDiffs = ({ maxTimestamp }) => {
  const [sensors, setSensors] = useState([]);
  const [sensorDiffs, setSensorDiffs] = useState([]);

  const loadSensors = async () => {
    try {
      const test = await sensorService.find({
        query: {
          disabled: false,
        },
      });
      setSensors(test.data);
      // console.log(test);
    } catch (e) {
      console.log(e);
    }
  };

  const loadDiff = async (sensorId, timeDiff) => {
    try {
      const test = await waterLevelService.find({
        query: {
          sensorId: sensorId,
          $limit: 1,
          $sort: {
            date: -1,
          },
          $select: ["level", "date"],
        },
      });

      const test2 = await waterLevelService.find({
        query: {
          sensorId: sensorId,
          date: {
            $lte: new Date(Date.now() - timeDiff * 1000).toISOString(),
          },
          $limit: 1,
          $sort: {
            date: -1,
          },
          $select: ["level", "date"],
        },
      });

      return [test.data[0], test2.data[0]];
    } catch (e) {
      console.log(e);
    }
  };

  const loadDiffs = useCallback(async () => {
    try {
      const bla = sensors.map((sensor) => loadDiff(sensor._id, 60 * 60 * 10));
      const ble = await Promise.all(bla);

      const bambam = sensors.map((sensor, i) => {
        return {
          ...sensor,
          levels: ble[i],
          diff: ble[i][0].level - ble[i][1].level,
        };
      });
      setSensorDiffs(bambam);
      // console.log(bambam);
    } catch (e) {
      console.log(e);
    }
  }, [sensors]);

  useEffect(() => {
    loadSensors();
  }, []);

  useEffect(() => {
    loadDiffs();
  }, [loadDiffs, maxTimestamp]);

  return (
    <div className="diffs-wrapper">
      <div className="diff-header">
        <h2>Pegelveränderungen über die letzten 10 Stunden</h2>
      </div>
      <div className="diff-gauges-wrapper">
        {sensorDiffs.map((sensor) => (
          <SensorDiff key={sensor._id} data={sensor} />
        ))}
      </div>
    </div>
  );
};

export default LevelDiffs;
